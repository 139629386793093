import React, {Component, Fragment} from "react";
import {ApiAxios} from '../../axiosConfig';
import EventBlock from "../Components/EventBlock";
import Loader from "../Components/Loader";

class Homepage extends Component {

    constructor() {
        super();
        this.state = {
            subjects: null,
            categories: null,
            selectedCategory: -1,
            selectedCategoryName: 'Vše',
        }
    }

    componentDidMount() {
        this._fetchData();
    }

    _fetchData = () => {
        ApiAxios.post('/v1/subjects')
            .then(res => {
                this.setState({
                    subjects: res.data
                });
            }).catch((err) => {
            console.error(`Chyba: ${err}`)
        });
        ApiAxios.post('/v1/subjects-categories')
            .then(res => {
                this.setState({
                    categories: res.data
                });
            }).catch((err) => {
            console.error(`Chyba: ${err}`)
        })
    };

    _renderCategories = () => {
        let arr = [];
        if (this.state.categories) {
            if (this.state.categories.data.length > 1) {
                arr.push(<div
                    key={-1}
                    className={"category-item" + (this.state.selectedCategory === -1 ? ' selected' : '')}
                    onClick={() => this.setState({selectedCategory: -1, selectedCategoryName: 'Vše'})}
                ><span>Vše</span></div>);
                this.state.categories.data.map((item, index) => {
                    return arr.push(
                        <div
                            key={index}
                            className={"category-item" + (this.state.selectedCategory === index ? ' selected' : '')}
                            onClick={() => this.setState({
                                selectedCategory: index,
                                selectedCategoryName: item.categoryName
                            })}
                        ><span>{item.categoryName}</span></div>
                    )
                });
                return arr;
            }
        }
    };

    _renderData = () => {
        let arr = [];
        if (this.state.subjects) {
            const Subjects = this.state.subjects.data.filter(sub =>
                this.state.selectedCategoryName !== "Vše" ? sub.cat_name === this.state.selectedCategoryName : sub);
            Subjects.map((item, index) => {
                return arr.push(
                    <div className={"col-lg-4 col-md-6 mb-4"} key={index}>
                        <EventBlock
                            name={item.name}
                            image={item.image}
                            link={item.short_url}
                            category={item.cat_name}
                        />
                    </div>
                )
            });
            return arr;
        } else {
            return <div className={"col-lg-12 text-center"}><Loader/></div>
        }
    };

    render() {
        return (
            <Fragment>
                <div className={"container"}>
                    {this.state.categories ?
                        this.state.categories.data.length > 1 ?
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="categorieslist">
                                        <div className="row justify-content-center">
                                            {this._renderCategories()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                        : ''
                    }
                    <div className="eventlist">
                        <div className="row">
                            {this._renderData()}
                        </div>
                    </div>
                </div>
                <section id={"douwant"}>
                    <div className="container-fluid px-0">
                        <div className="row no-gutters">
                            <div className="col-lg-12 text-center">
                                <h2>Chcete nás?</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={"products"}>
                    <div className="container-fluid px-0">
                        <div className="row no-gutters">
                            <div className="col-lg-12">
                                <div className="row no-gutters">
                                    <div className="col-lg-4 text-center">
                                        <a href={"https://www.enigoo.cz/"} target={"_blank"} rel="noopener noreferrer">
                                            <div className="enigoo-product-item enigoo">
                                                <div className="enigoo-product-item_content">
                                                    <img src="../img/enigoo_white.svg" draggable={false} alt="enigoo"/>
                                                    <p>Služba v oblasti ticketingu pro náročné.</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4 text-center">
                                        <a href={"https://www.enigoo.cz/box"} target={"_blank"}
                                           rel="noopener noreferrer">
                                            <div className="enigoo-product-item enigoobox">
                                                <div className="enigoo-product-item_content">
                                                    <img src="../img/enigoo_box.svg" draggable={false} alt="enigoo box"/>
                                                    <p>Odlehčená verze ENIGOO pro méně náročné.</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-lg-4 text-center">
                                        <a href={"https://safe.enigoo.cz/"} target={"_blank"} rel="noopener noreferrer">
                                            <div className="enigoo-product-item safegoo">
                                                <div className="enigoo-product-item_content">
                                                    <img src="https://safe.enigoo.cz/dist/img/safegoo_white.png"
                                                         draggable={false}
                                                         alt="safegoo"/>
                                                    <p>Kontrola vstupu - Face ID s termo snímačem.</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>

        )
    }
}

export default Homepage;