import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="footer_pushup">
                            <h2>Začněte prodávat s ENIGOO ještě dnes</h2>
                            <p>ENIGOO vám nabídne spolehlivý a rychlý systém k prodeji vstupenek a zabezpečení vaší
                                akce. Neustále rozvíjicí se systém se vám do budoucna vyplatí.</p>
                            <div className={"mt-4"}>
                                <a href="https://www.enigoo.cz/#contact" target={"_blank"} rel={"noopener noreferrer"} className={"btn btn-outline-light"}>Chci domluvit schůzku</a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer_groups">
                                    <div className="row">
                                        <div className="col-lg-3 d-flex align-items-center">
                                            <div className="footer_group_copyright">
                                                <img src="../img/enigoo_white.svg" className={"img-fluid"} alt=""/>
                                                <div>&copy; 2020 ENIGOO s.r.o.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="footer_group">
                                                <p>Navigace</p>
                                                <ul>
                                                    <li>
                                                        <Link to={"/"}>Vstupenky</Link>
                                                    </li>
                                                    <li>
                                                        <a href="https://enigoo.cz/" target={"_blank"} rel={"noopener noreferrer"}>ENIGOO</a>
                                                    </li>
                                                    <li>
                                                        <Link to={"/kontakt"}>Kontakt</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-2">
                                            <div className="footer_group">
                                                <p>ENIGOO</p>
                                                <ul>
                                                    <li>
                                                        <a href="https://enigoo.cz/" target={"_blank"} rel={"noopener noreferrer"}>ENIGOO</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://enigoo.cz/box" target={"_blank"} rel={"noopener noreferrer"}>ENIGOO box</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://safe.enigoo.cz/" target={"_blank"} rel={"noopener noreferrer"}>SAFEGOO</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"footer_contacts"}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className={"footer_contact"}>
                                                <div className={"footer_contant_phone"}>
                                                    <FontAwesomeIcon icon={"phone-alt"} className={"mr-3"}/> <a href="tel:+420461049232">+420 461 049 232</a>
                                                </div>
                                                <div className={"footer_contant_mail"}>
                                                    <FontAwesomeIcon icon={"envelope"} className={"mr-3"}/> <a href="mailto:info@enigoo.cz">info&#064;enigoo.cz</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className={"footer_soc"}>
                                                <a href="https://www.facebook.com/enigoo/" target={"_blank"} rel={"noopener noreferrer"}>
                                                    <FontAwesomeIcon icon={["fab", "facebook-square"]} />
                                                </a>
                                                <a href="https://www.instagram.com/enigoo.cz/" target={"_blank"} rel={"noopener noreferrer"}>
                                                    <FontAwesomeIcon icon={["fab", "instagram"]} />
                                                </a>
                                                <a href="https://www.linkedin.com/company/deep-vision-s.r.o./" target={"_blank"} rel={"noopener noreferrer"}>
                                                    <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;