import React from "react";

const ContactItem = ({icon, name, children}) => {
    return (
        <div className={"ContactItem"}>
            <img src={icon} className={"img-fluid"} alt="kontakt ikon" draggable={false}/>
            <p className={"ContactItem_name"}>{name}</p>
            <div>
                {children}
            </div>
        </div>
    )
};

export default ContactItem;