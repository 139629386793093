import React, {Component, Fragment} from "react";
import {ApiAxios} from '../../axiosConfig';
import Loader from "../Components/Loader";
import {Link} from "react-router-dom";

class Detail extends Component {

    constructor() {
        super();
        this.state = {
            subject: null,
        }
    }

    componentDidMount() {
        this._fetchData(this.props.match.params.url);
    }

    _fetchData = (paramUrl) => {
        ApiAxios.post('/v1/subject-detail', {url: paramUrl})
            .then(res => {
                this.setState({
                    subject: res.data
                });
            }).catch((err) => {
            console.error(`Chyba: ${err}`)
        })
    };

    _renderData = () => {
        if (this.state.subject) {
            if (this.state.subject.result) {
                return (
                    <Fragment>
                        <iframe src={this.state.subject.data.app} frameBorder="0"
                                title={"Prodejní aplikace"}>
                        </iframe>
                    </Fragment>
                )
            } else {
                return (
                    <div className={"container"}>
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4 text-center my-5">
                                <img src="../img/404.png" className={"img-fluid mb-3"} draggable={false}
                                     alt="subjekt nenlezen"/>
                                     <Link to={'/'}>Přejít na hlavní stránku</Link>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    };

    render() {
        return (
            <Fragment>
                {this.state.subject ?
                    <div className={"TicketDetail" + (!this.state.subject.result ? ' noexist' : '')}>
                        {this._renderData()}
                    </div>
                    :
                    <Loader/>
                }
            </Fragment>
        )
    }
}

export default Detail;