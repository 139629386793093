import React from "react";
import {Link} from "react-router-dom";

const EventBlock = ({name, image, link, category}) => {
    return(
        <div className={"EventBlock shadowed"}>
            <Link to={`vstupenky/${link}`}>
                <div className="EventBlock_wrapper">
                    <div className={"EventBlock_category shadowed"}>{category}</div>
                    <div className="EventBlock_header">
                        <div className="EventBlock_header_image" style={{background: `url(${process.env.REACT_APP_CDN}/${image})`}}></div>
                    </div>
                    <div className="EventBlock_body">
                        <div className="EventBlock_name">
                            <h2>{name}</h2>
                        </div>
                        <div className={"EventBlock_buy"}>
                            <span>Vstupenky</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
};

export default EventBlock;