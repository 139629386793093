import React from "react";
import ContactItem from "../../Components/ContactItem";

const Contact = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className={"Contact"}>
                        <h2>Kontakt</h2>
                        <div className="row">
                            <div className="col-lg-4">
                                <ContactItem icon={"../img/headset.png"} name={"Telefonická podpora"}>
                                    <a href="tel:+420461049232">+420461049232</a><br/>
                                    Každý den <strong>9:00 - 22:00</strong>
                                </ContactItem>
                            </div>
                            <div className="col-lg-4">
                                <ContactItem icon={"../img/at.png"} name={"Kontaktní e-mail"}>
                                    <a href="mailto:info@enigoo.cz">info&#064;enigoo.cz</a>
                                </ContactItem>
                            </div>
                            <div className="col-lg-4">
                                <ContactItem icon={"../img/message.png"} name={"Adresa firmy"}>
                                    Hlaváčova 207<br/>
                                    530 02 Pardubice, Česká republika<br/>
                                    IČ: 08750823, DIČ: CZ08750823
                                </ContactItem>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Contact;
