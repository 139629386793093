import React, {Component} from "react";
import Navbar from "react-bootstrap/Navbar";
import {Nav} from "react-bootstrap";

class Menu extends Component {


    render() {
        return (
            <Navbar bg="light" expand="lg">
                <div className="container">
                    <Navbar.Brand href={"/"}>
                        <img src="../img/enigoo_purple.svg" alt="logo"/>
                        <sup>market</sup>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className={"justify-content-end"}>
                        <Nav>
                            <Nav.Link href="/">Vstupenky</Nav.Link>
                            <Nav.Link href="/kontakt">Kontakt</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        )
    }
}

export default Menu;