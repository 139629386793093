import React, {Component, Fragment} from 'react';

import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';

library.add(fas, fab);

class App extends Component {

    render() {
        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        )
    }
}

export default App;
