import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './js/App';

import {createBrowserHistory} from 'history';
import {Router, Route, Switch} from "react-router-dom";

import Menu from "./js/Components/Menu";
import Footer from "./js/Components/Footer";

import Homepage from "./js/Pages/Homepage";
import Detail from "./js/Pages/Detail";
import Contact from "./js/Pages/Static/Contact";

const history = createBrowserHistory();

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <Menu/>
            <main className={"main-content"} role={"main"}>
                <App>
                    <Switch>
                        <Route path={'/'} exact={true} component={Homepage}/>
                        <Route path={'/vstupenky/:url'} exact={true} component={Detail}/>
                        <Route path={'/kontakt'} component={Contact}/>
                    </Switch>
                </App>
            </main>
            <Footer/>
        </Router>
    </React.StrictMode>,
    document.getElementById('app')
);

serviceWorker.unregister();
